<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t("inventory.Inventory") }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t("inventory.create_request") }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="box-title">ใบเบิกสินค้า</h4>
        </div>
        <div class="box-body p-3">
          <div class="form-group form-group-sm">
            <label class="form-label">เลขที่ใบเบิก</label>
            <input type="text"
                   v-model="form.document_number"
                   class="form-control form-control-sm">
          </div>
          <div class="form-group form-group-sm">
            <label class="form-label">วันที่เบิก</label>
            <input type="date"
                   class="form-control form-control-sm"
                   v-model="form.date">
          </div>
          <div class="form-group form-group-sm">
            <label class="form-label">หมายเหตุ</label>
            <textarea v-model="form.description"
                      rows="4"
                      class="form-control form-control-sm" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-6">
              <h4 class="box-title">รายการสินค้า</h4>
            </div>
            <div class="col-6 text-end">
              <button class="btn btn-primary btn-sm"
                      @click="$refs.newRequestModal.show()">+ Add New</button>
            </div>
          </div>
        </div>
        <div class="box-body p-3 min-h-300">
          <table class="table table-hover table-small">
            <thead>
            <tr>
              <th>ลำดับ</th>
              <th>{{ $t("inventory.product_id") }}</th>
              <th>ชื่อสินค้า</th>
              <th>หมวด</th>
              <th>หน่วยนับ</th>
              <th class="text-end">ราคา</th>
              <th class="text-center">จำนวน</th>
              <th class="text-center">ลบ</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(product, i) in products" :key="'product_'+i">
              <td class="text-center">{{ i+1 }}</td>
              <td>{{ product.product_id }}</td>
              <td>{{ product.product_name }}</td>
              <td>{{ product.category }}</td>
              <td>{{ product.unit }}</td>
              <td class="text-end">{{ product.sell_price }}</td>
              <td class="d-flex justify-content-center">
                <input type="text"
                       class="w-p60 form-control form-control-sm text-center"
                       :value="product.quantity">
              </td>
              <td class="text-center">
                <i class="fas fa-trash text-danger"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-md-6">
      <router-link :to="{name: 'inventory_list'}"
                   class="btn btn-secondary">ยกเลิกการสร้าง</router-link>
    </div>
    <div class="col-md-6 text-end">
<!--      <button class="btn btn-info me-2">บันทึกร่าง</button>-->
      <button class="btn btn-primary"
              :disabled="loading"
              @click="submit">ส่งใบเบิก</button>
    </div>
  </div>

  <new-request-modal @selected="productSelected" ref="newRequestModal" />
</template>

<script>
import NewRequestModal from "@/views/inventory/NewRequestModal";

export default {
  components: {NewRequestModal},
  data() {
    return {
      products: [],
      form: {
        document_number: '',
        date: '',
        description: '',
      }
    }
  },
  methods: {
    productSelected(products) {
      this.products = products;

      this.products.forEach(product => {
        product.quantity = 1;
      })
    },
    submit() {
      this.loading = true;

      this.form.products = this.products.map(p => ({
        id: p.product_id,
        product: p.product_name,
        quantity: p.quantity,
      }));

      this.axios.post('/inventory/create', this.form)
        .then(() => {
          this.$router.push({name: 'inventory_list'})
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>