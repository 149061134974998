<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('inventory.Inventory') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('inventory.list') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div :class="expanded ? 'col-8' : 'col-11'">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-8">
              <h4 class="box-title">รายการสินค้า</h4>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <div class="input-group">
            <input type="text"
                   v-model="query.q"
                   class="form-control"
                   placeholder="Search">
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </div>

          <hr>

          <div class="row">
            <div class="col-12">
              <div class="table-responsive max-h-350 tableFixHead">
                <table class="table table-small">
                  <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Name</th>
                    <th>{{ $t("inventory.remaining") }}</th>
                    <th>{{ $t("inventory.batch") }}</th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr v-if="loadingList">
                    <td colspan="4">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(product, i) in products" :key="'product_'+i">
                    <td>{{ product.product_id }}</td>
                    <td>{{ product.product_name }}</td>
                    <td>{{ product.balance }}</td>
                    <td>{{ product.warehouse }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-1"
         v-if="!expanded">
      <div class="box h-p100">
        <div class="box-body text-center">
          <a href="#"
             class="me-1"
             @click.prevent="expanded = true">
            <i class="fas fa-chevron-left"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="col-4" v-else>
      <div class="box mb-0">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-6">
              <h4 class="box-title">
                <a href="#"
                   class="me-1"
                   @click.prevent="expanded = false">
                  <i class="fas fa-chevron-right"></i>
                </a> {{ $t("inventory.stock_request") }}
              </h4>
            </div>
            <div class="col-md-6 text-end">
              <router-link :to="{name: 'inventory_request'}"
                           class="btn btn-primary btn-sm me-2">+ {{ $t('inventory.create') }}</router-link>
              <a href="#">{{ $t('inventory.view_all') }} ></a>
            </div>
          </div>
        </div>
        <div class="box-body p-2">
          <div class="d-flex justify-content-center" v-if="loadingStockRequests">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>

          <div class="d-flex align-items-center p-2 mb-2 bg-secondary-light"
               v-for="(req, i) in stockRequests"
               :key="'request_'+i">
            <div class="me-15 text-center p-3 rounded w-p30 text-white"
                 style="background-color: #0D6EFD;">
              <p class="fs-12 mb-0">สถานะ</p>
              <p class="fs-14 mb-0">{{ req.status }}</p>
            </div>
            <div class="d-flex flex-column flex-grow-1 fw-500">
              <router-link :to="{name: 'inventory_request_view', params: {id: req.transfer_id}}"
                           class="text-dark hover-primary mb-1 fs-16">{{ req.document_number }}</router-link>
              <span class="text-fade">วันที่เบิกสินค้า: {{ req.date }} <br>
หมายเหตุ: {{ req.description }}</span>
            </div>
            <div class="dropdown">
              <a class="px-10 pt-5" href="#" data-bs-toggle="dropdown"><i class="ti-more-alt"></i></a>
              <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item" href="#">
                  <i class="text-primary fas fa-file"></i> ดูรายละเอียด
                </a>
                <a class="dropdown-item"
                   @click.prevent="cancelRequest"
                   href="#">
                  <i class="text-danger fas fa-file-excel"></i> อัปเดทสถานะใบเบิก
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: true,
      loadingList: false,
      loadingStockRequests: false,
      allProducts: [],
      stockRequests: [],
      query: {
        q: ''
      }
    }
  },
  computed: {
    products() {
      if (this.query.q) {
        return this.allProducts.filter(p => p.product_id.includes(this.query.q) || p.product_name.includes(this.query.q))
      } else {
        return this.allProducts;
      }
    }
  },
  created() {
    this.loadData();
    this.loadStockRequests();
  },
  methods: {
    loadStockRequests() {
      this.stockRequests = [];
      this.loadingStockRequests = true;

      this.axios.get('/inventory/requests')
        .then(res => {
          this.stockRequests = res.data
        })
        .finally(() => {
          this.loadingStockRequests = false;
        })
    },
    loadData() {
      this.loadingList = true;
      this.axios.get('/inventory/list')
        .then(res => {
          this.allProducts = res.data;
        })
        .finally(() => {
          this.loadingList = false;
        })
    },
  }
}
</script>