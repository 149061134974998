<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t("inventory.stock_request") }} : 00133</h3>
      </div>
    </div>
  </div>

  <div class="row mb-3" v-if="requestDetails">
    <div class="col-3">
      <div class="box mb-0">
        <div class="box-header small-box-header">
          <h4 class="box-title">ใบเบิกสินค้า</h4>
        </div>
        <div class="box-body p-3">
          <div class="form-group form-group-sm">
            <label class="form-label">เลขที่ใบเบิก</label>
            <input type="text"
                   class="form-control"
                   :value="requestDetails.head.document_number" disabled>
          </div>
          <div class="form-group form-group-sm">
            <label class="form-label">สถานะใบเบิก</label>
            <input type="text"
                   class="form-control"
                   :value="requestDetails.head.status" disabled>
          </div>
          <div class="form-group form-group-sm">
            <label class="form-label">ผู้ทำรายการ</label>

            <div class="input-group">
              <input type="text" class="form-control" value="จงกลวรรณ สุวรรณชาตรี (มด)">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
            </div>
          </div>
          <div class="form-group form-group-sm">
            <label class="form-label">หมายเหตุ</label>

            <textarea class="form-control"
                      v-model="requestDetails.head.description"
                      rows="3" disabled />
          </div>
        </div>
      </div>
    </div>
    <div class="col-9">
      <div class="box h-p100">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-8">
              <h4 class="box-title mb-0">รายการสินค้า</h4>
            </div>
            <div class="col-4 text-end">
              <button class="btn btn-primary-light btn-sm">+ Add New</button>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <div class="table-responsive">
            <table class="table table-small">
              <thead>
              <tr>
                <th>ลำดับ</th>
                <th>Product ID</th>
                <th>ชื่อสินค้า</th>
                <th>หมวด</th>
                <th>หน่วยนับ</th>
                <th>ราคา</th>
                <th>จำนวน</th>
                <th>รับแล้ว</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(item, i) in requestDetails.detail" :key="'item_'+i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.product_id }}</td>
                <td>{{ item.product }}</td>
                <td>{{ item.department }}</td>
                <td>{{ item.unit }}</td>
                <td>{{ item.price }}</td>
                <td>{{ item.quantity }}</td>
                <td></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="box-title">รายการรับสินค้า</h4>
        </div>
        <div class="box-body p-3">
          <table class="table table-hover text-nowrap table-small">
            <thead>
            <tr>
              <th>ดู</th>
              <th>ลำดับ</th>
              <th>เลขเอกสาร</th>
              <th>วันที่</th>
              <th>สถานะ</th>
            </tr>
            </thead>

            <tbody>
            <tr>
              <td>
                <button class="btn btn-sm btn-light">
                  <i class="fas fa-chevron-down"></i>
                </button>
              </td>
              <td>1</td>
              <td>MR12345678</td>
              <td>1/12/2560</td>
              <td class="text-success">รับแล้ว</td>
            </tr>
            <tr>
              <td>
                <button class="btn btn-sm btn-light">
                  <i class="fas fa-chevron-up"></i>
                </button>
              </td>
              <td>1</td>
              <td>MR12345678</td>
              <td>1/12/2560</td>
              <td class="text-warning">รับแล้ว</td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="3">
                <table class="table table-hover text-nowrap table-small">
                  <thead>
                  <tr>
                    <th>ลำดับ</th>
                    <th>Product ID</th>
                    <th>ชื่อสินค้า</th>
                    <th>หมวด</th>
                    <th>หมู่</th>
                    <th>หน่วยนับ</th>
                    <th>Barcode/Lot Number</th>
                    <th>จำนวน</th>
                    <th class="text-end">สถานะ</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>1</td>
                    <td>04-04-004-002</td>
                    <td>โบท็อกเกาหลี 10CC</td>
                    <td>4 Consumable</td>
                    <td>404 General Consumables</td>
                    <td>ยูนิต</td>
                    <td>123134</td>
                    <td>15.00</td>
                    <td class="text-end">
                      <i class="fas fa-check"></i>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-md-6">
      <button class="btn btn-secondary">ยกเลิกใบเบิก</button>
    </div>
    <div class="col-md-6 text-end">
      <button class="btn btn-info me-1">บันทึกร่าง</button>
      <button class="btn btn-primary">ยืนยันการรับสินค้า</button>
    </div>
  </div>

  <good-receiving-modal ref="goodReceivingModal" />
</template>

<script>
import GoodReceivingModal from "@/views/inventory/modals/GoodReceivingModal";
export default {
  components:{ GoodReceivingModal},
  data() {
    return {
      requestDetails: null,
      requestStatusDetails: null,
      items: []
    }
  },
  created() {
    this.loadRequest();
    this.loadRequestStatus();
  },
  methods: {
    loadRequestStatus() {
      this.requestStatusDetails = null;

      this.axios.get('/inventory/requests/status/' + this.$route.params.id)
        .then(res => {
          this.requestStatusDetails = res.data;
        })
    },
    loadRequest() {
      this.requestDetails = null;

      this.axios.get('/inventory/requests/' + this.$route.params.id)
        .then(res => {
          this.requestDetails = res.data;
        })
    }
  }
}
</script>