<template>
  <div v-if="caseObj">
    <div class="row">
      <div class="col-6">
        <h4>{{ $t("inventory.manage_consumable") }} : {{ caseObj.case_no }}</h4>
      </div>
      <div class="col-6 text-end">
        <select v-if="editingStatus"
                v-model="statusForm.status"
                class="form-select form-select-sm d-inline w-auto">
          <option value="0">Pending</option>
          <option value="1">Ready</option>
          <option value="2">Finished</option>
          <option value="3">Cancel</option>
        </select>

        <consumable-status :status="caseObj.consumable_status" v-else />

        <button class="btn btn-sm btn-secondary ms-2"
                v-if="!editingStatus"
                @click="editStatus">
          <i class="fas fa-edit"></i>
        </button>

        <button class="btn btn-sm btn-success ms-2"
                v-else
                @click="saveStatus">
          <i class="fas fa-save"></i>
        </button>

        <button class="btn btn-info btn-sm ms-2 me-2"
                @click="$refs.selectProductModal.show()">เพิ่มรายการ</button>
        <button class="btn btn-primary btn-sm me-2">สแกนบาร์โค้ด</button>
<!--        <button class="btn btn-success btn-sm">เสร็จสิ้น</button>-->
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-3">
        <div class="box">
          <div class="box-header small-box-header">
            <h4 class="box-title">Assigned Doctor</h4>
          </div>
          <div class="box-body p-3">
            <div class="d-flex align-items-center">
              <div class="me-15">
                <img :src="caseObj.doctor.image ? caseObj.doctor.image : '/images/avatar-girl.png'"
                     onerror="this.src='/images/avatar-girl.png'"
                     class="avatar avatar-lg rounded10 bg-primary-light" />
              </div>
              <div class="d-flex flex-column flex-grow-1 fw-500">
                <a href="#" class="text-dark hover-primary mb-1 fs-16">{{ caseObj.doctor.full_name }}</a>
                <span class="text-fade">{{ caseObj.doctor.department.name }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="box mb-0">
          <div class="box-header small-box-header">
            <h4 class="box-title">ข้อมูลผู้รับบริการ</h4>
          </div>
          <div class="box-body p-3">
            <div class="row">
              <div class="col-8">
                <table class="patient-details-table table table-borderless table-sm">
                  <tbody>
                  <tr>
                    <td>
                      <span style="color:#5156BE;">◆</span> HN
                    </td>
                    <td>: {{ caseObj.patient.hn }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color:#5156BE;">◆</span> รหัสลูกค้า
                    </td>
                    <td>: {{ caseObj.patient.patient_id }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color:#5156BE;">◆</span> ชื่อลูกค้า
                    </td>
                    <td>: {{ caseObj.patient.full_name }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color:#5156BE;">◆</span> ชื่อเล่น
                    </td>
                    <td>: {{ caseObj.patient.nick_name }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color:#5156BE;">◆</span> อายุ
                    </td>
                    <td>: 27 ปี</td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color:#5156BE;">◆</span> หมู่เลือด
                    </td>
                    <td>: {{ caseObj.patient.bloodGroup.name }}</td>
                  </tr>
                  </tbody>
                </table>

                <hr>

                <span style="color:#5156BE;">◆</span> ข้อมูลบริการ

                <div class="ps-3">
                  <template v-for="(service, i) in caseObj.services"
                            :key="'service_'+service.id">
                    <span>{{ i+1 }}. {{ service.name_th }}</span> <br>
                  </template>
                </div>
              </div>
              <div class="col-4">
                <img :src="caseObj.patient.image ? caseObj.patient.image : '/images/avatar-girl.png'"
                     onerror="this.src='/images/avatar-girl.png'"
                     class="w-p100">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="box h-p100">
          <div class="box-header small-box-header">
            <h4 class="box-title">วัสดุสิ้นเปลือง</h4>
          </div>
          <div class="box-body p-3">
            <table class="table table-small">
              <thead>
              <tr>
                <th>ลำดับ</th>
                <th>รหัสสินค้า</th>
                <th>ชื่อสินค้า</th>
                <th>ราคา</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in consumables" :key="'item_'+item.id">
                <td>{{ i+1 }}</td>
                <td>{{ item.product.code }}</td>
                <td>{{ item.product.name_sale }}</td>
                <td>{{ item.product.selling_price }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <select-product-modal @selected="addProduct"
                        ref="selectProductModal" />
</template>

<script>
import SelectProductModal from "@/components/modals/SelectProductModal.vue";
import ConsumableStatus from "@/views/inventory/consumable/ConsumableStatus.vue";
export default {
  components: {ConsumableStatus, SelectProductModal},
  data() {
    return {
      caseObj: null,
      editingStatus: false,
      consumables: [],
      statusForm: {
        status: ''
      },
    }
  },
  created() {
    this.loadCase();
    this.loadConsumables();
  },
  methods: {
    loadConsumables() {
      this.consumables = [];

      this.axios.get('/visit-cases/consume/' + this.$route.params.id)
        .then(res => {
          this.consumables = res.data.data;
        })
    },
    loadCase() {
      this.caseObj = null;

      this.axios.get('/visit-cases/' + this.$route.params.id)
        .then(res => {
          this.caseObj = res.data.data;
          this.selectedStaffs = this.caseObj.staffs.map(s => s.id);
        })
    },
    editStatus() {
      this.statusForm.status = this.caseObj.consumable_status;
      this.editingStatus = true;
    },
    saveStatus() {
      this.statusForm.visit_case_id = this.caseObj.id;

      this.axios.post('/visit-cases-consume/change-status', this.statusForm)
        .then(() => {
          this.caseObj.consumable_status = this.statusForm.status;
          this.editingStatus = false;
        })
    },
    addProduct(productId) {
      if (productId) {
        this.axios.post('/visit-cases/consume/' + this.$route.params.id, {
            product_id: productId
          })
          .then(() => this.loadConsumables())
      }
    }
  }
}
</script>