<template>
  <div class="modal fade bs-example-modal-lg"
       id="new-request-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            เลือกรายการสินค้าที่ต้องการเบิก
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group form-group-sm row mb-0">
            <label class="col-sm-1 col-form-label">คำค้นหา</label>
            <div class="col-sm-10">
              <div class="input-group">
                <input type="text" class="form-control form-control-sm">
                <span class="input-group-text"><i class="fa fa-search"></i></span>
              </div>
            </div>
            <div class="col-sm-1">
              <div class="d-grid">
                <button class="btn btn-primary btn-sm">ค้นหา</button>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-1 col-form-label">หมวดหมู่</label>
            <div class="col-sm-3">
              <select class="form-select form-select-sm"></select>
            </div>
            <label class="col-sm-1 col-form-label">หมู่</label>
            <div class="col-sm-3">
              <select class="form-select form-select-sm"></select>
            </div>
            <div class="col-sm-3">
              <div class="checkbox checkbox-success">
                <input id="checkbox2" type="checkbox">
                <label for="checkbox2">น้อยกว่าหรือเท่ากับ Safegy Stock</label>
              </div>
            </div>
            <div class="col-sm-1">
              <div class="d-grid">
                <button class="btn btn-info btn-sm">เคลียร์</button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="box">
                <div class="box-body p-3">
                  <table class="table table-small">
                    <thead>
                    <tr>
                      <th>เลือก</th>
                      <th>รหัสสินค้า</th>
                      <th>ชื่อสินค้า (ขาย)</th>
                      <th>หมวด</th>
                      <th>หมู่</th>
                      <th class="text-end">คงเหลือ</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(product, i) in products" :key="'item_'+i">
                      <td>
                        <div class="checkbox checkbox-success">
                          <input :id="'product_'+product.product_id"
                                 type="checkbox"
                                 v-model="selectedProductIds"
                                 :value="product.product_id">
                          <label :for="'product_'+product.product_id"></label>
                        </div>
                      </td>
                      <td>{{ product.product_id }}</td>
                      <td>{{ product.product_name }}</td>
                      <td>{{ product.category }}</td>
                      <td>{{ product.unit }}</td>
                      <td class="text-end">{{ product.balance }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-end">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ปิดหน้าจอ</button>

          <button class="btn btn-primary" @click="selectProducts">เพิ่มรายการที่เลือก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      selectedProductIds: [],
      products: [],
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('new-request-modal'));
  },
  created() {
    this.loadProducts();
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadProducts() {
      this.products = [];

      this.axios.get('/inventory/products')
        .then(res => {
          this.products = res.data;
        });
    },
    selectProducts() {
      let products = this.products.filter(p => this.selectedProductIds.includes(p.product_id));

      this.hide();
      this.$emit('selected', products);
    }
  }
}
</script>