<template>
  <div class="modal fade bs-example-modal-lg"
       id="select-product-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Select Product
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div :class="['form-group form-group-sm row mb-0']">
            <label class="col-sm-2 col-form-label">พนักงาน</label>
            <div :class="['col-sm-10 form-group mb-2']">
              <select class="form-select form-select-sm select2 product-select2"
                      style="width: 100%;"
                      v-model="product_id">
                <option value="">Select Product</option>
                <option :value="product.product_id"
                        v-for="product in products"
                        :key="'product_'+product.id">{{ product.product_name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิกการสร้าง</button>

          <button class="btn btn-success"
                  :disabled="loading || !product_id"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      products: [],
      product_id: '',
    }
  },
  created() {
    this.loadProducts();
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('select-product-modal'));
  },
  methods: {
    resetForm() {
      this.product_id = '';
    },
    show() {
      this.resetForm();

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadProducts() {
      this.products = [];

      this.axios.get('/inventory/products')
        .then(res => {
          this.products = res.data;

          setTimeout(() => {
            this.initSelect2();
          }, 100)
        })
    },
    initSelect2() {
      let self = this;

      window.$('.product-select2').select2({
        placeholder: 'โปรดระบุ',
        dropdownParent: window.$('#select-product-modal')
      });

      window.$('.product-select2').on('change.select2', function () {
        self.product_id = window.$(this).val();
      });
    },
    submit() {
      this.hide();

      this.$emit('selected', this.product_id)
    },
  }
}
</script>