<template>
  <div class="modal fade bs-example-modal-lg"
       id="good-receiving-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true"
       style="display: none;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            ลงเวลาวันทำงานของแพทย์
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="form-label">จำนวนที่ขอเบิก</label>
                <input type="text"
                       class="form-control form-control-sm"
                       value="15.00" disabled>
              </div>
              <div class="form-group">
                <label class="form-label">จำนวนที่รับ</label>
                <input type="text"
                       class="form-control form-control-sm bg-info-light"
                       value="0.00" disabled>
              </div>
              <div class="form-group">
                <label class="form-label">จำนวนคงเหลือ</label>
                <input type="text"
                       class="form-control form-control-sm bg-warning-light"
                       value="15.00" disabled>
              </div>
            </div>
            <div class="col-md-8">
              <div class="box">
                <div class="box-body p-3">
                  <div class="table-responsive">
                    <table class="table table-small">
                      <thead>
                      <tr>
                        <th>ลำดับ</th>
                        <th>รายการที่รับ</th>
                        <th class="text-end">ลบ</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="item in items" :key="'item_'+item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.col1 }}</td>
                        <td class="text-end">
                          <i class="fas fa-trash"></i>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิก</button>

          <button class="btn btn-success" @click="receiveGoods">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      items: [
        {
          id: 1,
          col1: '08806940001013',
        },
        {
          id: 2,
          col1: '08806940002013',
        },
        {
          id: 3,
          col1: '08806940003013',
        }
      ]
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('good-receiving-modal'));
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    receiveGoods() {
      this.$swal({
        title: 'ยืนยันการรับสินค้า',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#6F42C1',
        cancelButtonColor: '#0D6EFD',
        reverseButtons: true
      });
    }
  }
}
</script>